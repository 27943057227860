<template>
  <div class="frequency_box" v-loading="loading">
    <div class="title">广告价目表</div>
    <div v-if="detailInfo">
      <div class="detail_time">
        {{ parseTime(detailInfo.createdAt) }} &nbsp; &nbsp; 编辑：{{ detailInfo.authorName }} &nbsp; &nbsp; 点击量：{{
          detailInfo.viewBaseNum }}
      </div>
      <div class="mian_img" v-html="detailInfo.content">
      </div>
    </div>
    <share-con></share-con>
  </div>
</template>

<script>
  import shareCon from '@/components/shareCon'
  import {
    APPID,
    ADVERT
  } from '@/utils/api/config'
  import {
    parseTime
  } from "@/utils/tools"
  import fenxiang from '../../../public/shareConfig.js';
  export default {
    components: {
      shareCon
    },
    data() {
      return {
        detailInfo: null,
        loading: true
      };
    },
    created() {
      //由于不知名原因导致无法分享 刷新后可以 所以这里刷新一下页面
      if (this.$route.query.reload) {
        this.$router.replace({
          name: this.$route.name
        })
        window.location.reload()
      } else {
        this.getData()
      }
    },
    methods: {
      //获取频道第一条数据
      getData() {
        this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${ADVERT}&page=1&limit=1000`).then(res => {
          if (res.code !== '200') {
            this.$message.error(res.msg)
            return
          }
          let id = res.data.list[0].id
          this.axios.get(`api/fusion/pc/getNewsInfo?appId=${APPID}&newsId=${id}`).then(result => {
            this.detailInfo = result.data
            fenxiang('广告价目表', '',
              'http://o.cztvcloud.com/195/4727937/images/webologo.jpg', window.location.href);
            this.loading = false
          })
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .frequency_box {
    padding: 80px 30px;

    .title {
      font-size: 36px;
      text-align: center;
      font-weight: bold;
    }

    .detail_time {
      text-align: center;
      margin: 0 auto;
      font-size: 14px;
      color: #666;
      margin-top: 20px;
      margin-bottom: 50px;
    }

    .mian_img {
      margin: 10px 0px;
      text-align: center;

      img {
        width: 650px;
        margin-bottom: 60px;
      }
    }
  }
</style>